<template>
  <v-dialog
    v-model="isImportFileDrug"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('import_drug_files') }} <v-spacer></v-spacer>
        <v-tooltip
          color="#212121"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <a :href="drug_form">
              <v-btn
                color="primary"
                v-bind="attrs"
                x-small
                fab
                v-on="on"
              >
                <v-icon>{{ icons.mdiCloudDownloadOutline }}</v-icon>
              </v-btn></a>
          </template>
          <span>{{ $t("download_form") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-form
        ref="formImportDrug"
        @submit.prevent="uploadFileDrug"
      >
        <v-card-text>
          <v-autocomplete
            v-model="druggroup_id"
            :items="drugGroups"
            dense
            outlined
            :rules="[required]"
            :no-data-text="`${$t('no_data')} !`"
            :label="$t('choose_drug_group')"
            item-text="druggroup_name"
            item-value="druggroup_id"
          ></v-autocomplete>
          <v-autocomplete
            v-model="drugtype_id"
            :rules="[required]"
            dense
            outlined
            :items="drugTypes"
            :no-data-text="`${$t('no_data')} !`"
            :label="$t('drug_type')"
            item-text="drugtype_name"
            item-value="drugtype_id"
          ></v-autocomplete>
          <v-autocomplete
            v-model.trim="user_id"
            :items="userList"
            :label="$t('doctor')"
            :no-data-text="`${$t('no_data')} !`"
            dense
            outlined
            :rules="[required]"
            item-text="user_fullname"
            item-value="user_id"
            :auto-select-first="true"
          ></v-autocomplete>
          <v-file-input
            v-model="files"
            dense
            outlined
            :label="$t('choose_a_file')"
            show-size
            accept=".csv"
            @change="checkFile"
          ></v-file-input>
          <span class="ml-3">Log</span> <span
            v-if="checkLoading"
            class="primary--text"
          >{{ $t('checkDataLoading') }}</span>
          <v-sheet
            outlined
            rounded="lg"
            height="100"
          >
            <v-virtual-scroll
              :items="dataCheckFile.data"
              :item-height="20"
              height="100"
            >
              <template v-slot:default="{ item,index }">
                <span
                  class="text-sm ml-2"
                  :class="item.status_id==0?'error--text':'success--text'"
                > {{ index+1 }}) {{ item.status_id==0? item.status_message :`ข้อมูลถูกต้อง (${item.drug_id} ${item.drug_name})` }} <v-icon
                  small
                  :color="item.status_id==0?'error':'success'"
                >{{ item.status_id==0? icons.mdiClose:icons.mdiCheck }}</v-icon> </span>
              </template>
            </v-virtual-scroll>
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || !files||dataCheckFile.checkfalse>=1 || checkLoading"

            type="submit"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-import-file-drug',false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCloudDownloadOutline } from '@mdi/js'
import drugGroup from '@/api/DrugManagement/drugGroup'
import drugType from '@/api/DrugManagement/drugType'
import userRole from '@/api/userRole'
import { required } from '@/@core/utils/validation'
import drug_form from '@/assets/download_files/drug_form.csv'
import drugStore from '@/api/DrugManagement/drugStore'
import store from '@/store'
import checkImportFile from '@/api/checkImportFile'

export default {
  model: {
    prop: 'isImportFileDrug',
    event: 'update:is-import-file-drug',
  },
  props: {
    isImportFileDrug: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const files = ref(null)
    const drugGroups = ref([])
    const drugTypes = ref([])
    const userList = ref([])
    const druggroup_id = ref('')
    const drugtype_id = ref('')
    const user_id = ref('')
    const formImportDrug = ref(null)
    const loading = ref(false)
    const checkLoading = ref(false)
    const dataCheckFile = ref({})

    const { drugGroupList } = drugGroup
    const { drugTypeList } = drugType
    const { userRoleList } = userRole
    const { drugImportFile } = drugStore

    drugGroupList({
      searchtext: '',
      drug_category_id: '1',
      druggroup_status_id: '1',
    }).then(res => {
      drugGroups.value = res.data
      druggroup_id.value = drugGroups.value[0].druggroup_id
    })

    drugTypeList({
      searchtext: '',
      drug_category_id: '1',
      drugtype_status_id: '1',
    }).then(res => {
      drugTypes.value = res.data
      drugtype_id.value = drugTypes.value[0].drugtype_id
    })
    userRoleList({
      role_id: ['1', '3'],
    }).then(res => {
      userList.value = res
      user_id.value = localStorage.getItem('user_id')
    })
    const uploadFileDrug = () => {
      const isFormValid = formImportDrug.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('druggroup_id', druggroup_id.value)
      formData.append('drugtype_id', drugtype_id.value)
      formData.append('user_id', user_id.value)
      formData.append('file', files.value)
      drugImportFile(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-import-file-drug', false)
        user_id.value = localStorage.getItem('user_id')
        files.value = []
        drugtype_id.value = drugTypes.value[0].drugtype_id
        druggroup_id.value = drugGroups.value[0].druggroup_id
        emit('onImport')
      })
    }

    const checkFile = () => {
      checkLoading.value = true
      const formData = new FormData()
      formData.append('druggroup_id', druggroup_id.value)
      formData.append('drugtype_id', drugtype_id.value)
      formData.append('user_id', user_id.value)
      formData.append('file', files.value)
      checkImportFile.checkImportFileDrug(formData).then(res => {
        dataCheckFile.value = res
        checkLoading.value = false
      })
    }

    return {
      files,
      loading,
      drugGroups,
      drugTypes,
      userList,
      user_id,
      druggroup_id,
      drugtype_id,
      checkFile,
      formImportDrug,
      checkLoading,
      dataCheckFile,
      uploadFileDrug,
      required,
      drug_form,
      icons: {
        mdiCloudDownloadOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
