<template>
  <v-dialog
    v-model="isEditDrug"
    persistent
    max-width="900"
  >
    <v-card>
      <v-card-title>
        {{ $t('editDrug') }}
      </v-card-title>
      <v-form
        ref="formEditDrug"
        @submit.prevent="updateDataDrug"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <label>
                {{ $t("category") }}{{ $t("drug") }}
              </label>
              <v-autocomplete
                v-model="dataEditLocal.druggroup_id"
                outlined
                dense
                :items="drugGroups"
                :no-data-text="$t('no_information')"
                item-text="druggroup_name"
                item-value="druggroup_id"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <label>
                {{ $t("type") }}{{ $t("drug") }}
              </label>
              <v-autocomplete
                v-model="dataEditLocal.drugtype_id"
                outlined
                dense
                :items="drugTypes"
                item-text="drugtype_name"
                :no-data-text="$t('no_information')"
                item-value="drugtype_id"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <label>
                {{ $t("code")
                }}{{ $t("drug") }}
              </label>
              <v-text-field
                v-model="dataEditLocal.drug_id"
                :rules="[required]"
                outlined
                :disabled="dataEditLocal.drug_total == null || dataEditLocal.drug_total == 0"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <label>
                {{ $t("name") }} {{ $t("drug") }}
                <span class="text-danger">*</span>
              </label>
              <v-text-field
                v-model="dataEditLocal.drug_name"
                :rules="[required]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <label>
                {{ $t("sale_price") }}
                <span class="text-danger">*</span>
              </label>
              <v-text-field
                v-model="dataEditLocal.drug_price"
                outlined
                :rules="[required]"
                dense
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <label>
                {{ $t("unit") }}
                <span class="text-danger">*</span>
              </label>
              <v-text-field
                v-model="dataEditLocal.drug_unit"
                :rules="[required]"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <label>
                {{ $t("direction") }}
              </label>
              <v-textarea
                v-model="dataEditLocal.drug_direction"
                outlined
                dense
                rows="2"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <label>
                {{ $t("detail") }}
              </label>
              <v-textarea
                v-model="dataEditLocal.drug_detail"
                outlined
                dense
                rows="2"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="dataEditLocal.user_id"
                outlined
                dense
                :label="`${$t('doctor')} ${$t('grantee')}`"
                hide-details
                :items="userList"
                item-value="user_id"
                item-text="user_fullname"
              >
              </v-select>
            </v-col>

            <v-col cols="12">
              <label
                for=""
                class=" font-weight-semibold"
              >{{ $t("type_potency") }}</label>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex "
            >
              <v-checkbox
                v-model="dataEditLocal.drug_narcotic2"
                class="mt-0"
                color="#303d4a"
                true-value="1"
                false-value="0"
                hide-details
              >
                <template v-slot:label>
                  <span style="color:#303d4a;">{{
                    `${$t("narcotics_of_drugs_category_type_2")}  (${$t(
                      "used_to_issue_the_report_2"
                    )})`
                  }}</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex"
            >
              <v-checkbox
                v-model="dataEditLocal.drug_narcotic3"
                class="mt-0"
                color="#f88c01"
                true-value="1"
                false-value="0"
                hide-details
              >
                <template v-slot:label>
                  <span style="color:#f88c01">{{
                    `${$t("narcotics_of_drugs_category_type_3")}  (${$t(
                      "used_to_issue_the_report_3"
                    )})`
                  }}</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex"
            >
              <v-checkbox
                v-model="dataEditLocal.drug_narcotic4"
                class="mt-0"
                color="red"
                true-value="1"
                false-value="0"
                hide-details
              >
                <template v-slot:label>
                  <span style="color:red">{{
                    `${$t("narcotics_of_drugs_category_type_4")}  (${$t(
                      "used_to_issue_the_report_4"
                    )})`
                  }}</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-md-flex"
            >
              <v-checkbox
                v-model="dataEditLocal.drug_narcotic5"
                class="mt-0"
                color="#398bf7"
                true-value="1"
                false-value="0"
              >
                <template v-slot:label>
                  <span style="color:#398bf7">{{
                    `${$t("narcotics_of_drugs_category_type_5")}  (${$t(
                      "used_to_issue_the_report_5"
                    )})`
                  }}</span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn
            color="primary"
            type="submit"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-edit-drug', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import drugGroup from '@/api/DrugManagement/drugGroup'
import drugType from '@/api/DrugManagement/drugType'
import drugStore from '@/api/DrugManagement/drugStore'
import store from '@/store'
import user from '@/api/systemSetting/user'

export default {
  model: {
    prop: 'isEditDrug',
    event: 'update:is-edit-drug',
  },
  props: {
    isEditDrug: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const dataEditLocal = ref({})
    const loading = ref(false)
    const formEditDrug = ref(null)
    const drugGroups = ref([])
    const drugTypes = ref([])
    const userList = ref([])

    const { userList: userListGet } = user

    const getUser = () => {
      userListGet(
        {
          searchtext: '',
          user_status_id: '1',
          active_page: 1,
          per_page: 0,
        },
      ).then(res => {
        userList.value = res.data
      })
    }

    const { drugGroupList } = drugGroup
    const { drugTypeList } = drugType

    const getDrugGroup = () => {
      drugGroupList({
        searchtext: '',
        drug_category_id: '1',
        druggroup_status_id: '1',
      }).then(res => {
        drugGroups.value = res.data
      })
    }

    const getDrugType = () => {
      drugTypeList({
        searchtext: '',
        drug_category_id: '1',
        drugtype_status_id: '1',
      }).then(res => {
        drugTypes.value = res.data
      })
    }

    const { drugUpdate } = drugStore

    const updateDataDrug = () => {
      const isFormValid = formEditDrug.value.validate()
      if (!isFormValid) return
      loading.value = true
      const body = {
        drug_id_pri: dataEditLocal.value.drug_id_pri,
        drug_id: dataEditLocal.value.drug_id,
        drugtype_id: dataEditLocal.value.drugtype_id,
        druggroup_id: dataEditLocal.value.druggroup_id,
        drug_name: dataEditLocal.value.drug_name,
        drug_price: dataEditLocal.value.drug_price,
        drug_unit: dataEditLocal.value.drug_unit,
        drug_direction: dataEditLocal.value.drug_direction,
        drug_detail: dataEditLocal.value.drug_detail,
        user_id: dataEditLocal.value.user_id,
        drug_narcotic2: dataEditLocal.value.drug_narcotic2,
        drug_narcotic3: dataEditLocal.value.drug_narcotic3,
        drug_narcotic4: dataEditLocal.value.drug_narcotic4,
        drug_narcotic5: dataEditLocal.value.drug_narcotic5,
      }
      drugUpdate(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-edit-drug', false)
        emit('onUpdate')
      })
    }

    watch(() => props.isEditDrug, newVal => {
      if (newVal) {
        getDrugType()
        getDrugGroup()
        getUser()
        props.dataEdit.user_id = +props.dataEdit.user_id
        dataEditLocal.value = props.dataEdit
      }
    })

    return {
      userList,
      drugTypes,
      drugGroups,
      loading,
      dataEditLocal,
      formEditDrug,
      required,
      updateDataDrug,
      textValidation,
    }
  },
}
</script>
