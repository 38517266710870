<template>
  <div>
    <v-dialog
      v-model="isAddNewDrugStore"
      max-width="950"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t("add") }}{{ $t("drug") }}/{{ $t("equipment") }}
          <v-spacer></v-spacer>
          <v-radio-group
            v-model="typeAdd"
            class="mt-0 pt-0"
            hide-details
            row
          >
            <v-radio
              style="font-size:20px"
              :label="`${$t('add_drug')}`"
              value="1"
            ></v-radio>
            <v-radio
              style="font-size:20px"
              :label="`${$t('add_equipment')}`"
              value="2"
            ></v-radio>
          </v-radio-group>
        </v-card-title>
        <v-form
          ref="formAddDrugStore"
          @submit.prevent="createDrugStore"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model.trim="druggroup_id"
                  outlined
                  :label="$t('category')"
                  dense
                  :rules="[required]"
                  :items="drugGroups"
                  item-text="druggroup_name"
                  :no-data-text="$t('no_information')"
                  item-value="druggroup_id"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model.trim="drugtype_id"
                  outlined
                  :label="$t('type')"
                  :rules="[required]"
                  dense
                  :items="drugTypes"
                  item-text="drugtype_name"
                  item-value="drugtype_id"
                  :no-data-text="$t('no_information')"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.trim="drug_id"
                  :rules="[required]"

                  outlined
                  :label="$t('code')"
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.trim="drug_name"
                  :rules="[required]"

                  outlined
                  :label="$t('name')"
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.trim="drug_price"
                  outlined

                  :label="$t('sale_price')"
                  :rules="[required]"
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.trim="drug_unit"
                  :rules="[required]"
                  :label="$t('unit')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  v-model.trim="drug_direction"
                  outlined
                  :label="$t('direction')"

                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  v-model="drug_detail"
                  outlined
                  :label="$t('detail')"
                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
              <div class="col col-12">
                <v-select
                  v-model.trim="user_id"
                  outlined
                  dense
                  :label="`${$t('doctor') + ' ' + $t('grantee')}`"
                  hide-details
                  :items="userList"
                  item-value="user_id"
                  item-text="user_fullname"
                >
                </v-select>
              </div>

              <div
                v-if="typeAdd == 1"
                class="col col-12 mt-3"
              >
                <label
                  for=""
                  class="font-weight-semibold text-xl-body-1"
                >{{ $t("type_potency") }}</label>
              </div>
              <div
                v-if="typeAdd == 1"
                class="col col-12 col-sm-12 col-md-6 my-0 d-md-flex"
              >
                <v-checkbox
                  v-model="drug_narcotic2"
                  class="mt-0"
                  color="#303d4a"
                  hide-details
                  true-value="1"
                  false-value="0"
                >
                  <template v-slot:label>
                    <span style="color:#303d4a">{{
                      `${$t("narcotics_of_drugs_category_type_2")}  (${$t(
                        "used_to_issue_the_report_2"
                      )})`
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
              <div
                v-if="typeAdd == 1"
                class="col col-12 col-sm-12 col-md-6 my-0 d-md-flex"
              >
                <v-checkbox
                  v-model="drug_narcotic3"
                  class="mt-0"
                  color="#f88c01"
                  hide-details
                  true-value="1"
                  false-value="0"
                >
                  <template v-slot:label>
                    <span style="color:#f88c01">{{
                      `${$t("narcotics_of_drugs_category_type_3")}  (${$t(
                        "used_to_issue_the_report_3"
                      )})`
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
              <div
                v-if="typeAdd == 1"
                class="col col-12 col-sm-12 col-md-6 my-0 d-md-flex"
              >
                <v-checkbox
                  v-model="drug_narcotic4"
                  class="mt-0"
                  hide-details
                  color="red"
                  true-value="1"
                  false-value="0"
                >
                  <template v-slot:label>
                    <span style="color:red">{{
                      `${$t("narcotics_of_drugs_category_type_4")}  (${$t(
                        "used_to_issue_the_report_4"
                      )})`
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
              <div
                v-if="typeAdd == 1"
                class="col col-12 col-sm-12 col-md-6 my-0 d-md-flex"
              >
                <v-checkbox
                  v-model="drug_narcotic5"
                  class="mt-0"
                  hide-details
                  color="#398bf7"
                  true-value="1"
                  false-value="0"
                >
                  <template v-slot:label>
                    <span style="color:#398bf7">{{
                      `${$t("narcotics_of_drugs_category_type_5")}  (${$t(
                        "used_to_issue_the_report_5"
                      )})`
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-drug-store', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import drugStore from '@/api/DrugManagement/drugStore'
import drugGroup from '@/api/DrugManagement/drugGroup'
import drugType from '@/api/DrugManagement/drugType'
import store from '@/store'
import document from '@/api/systemSetting/document'
import userRole from '@/api/userRole'

export default {
  model: {
    prop: 'isAddNewDrugStore',
    event: 'update:is-add-new-drug-store',
  },
  props: {
    isAddNewDrugStore: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const formAddDrugStore = ref(null)
    const loading = ref(false)
    const typeAdd = ref('1')
    const drug_id = ref('')
    const drug_name = ref('')
    const drug_price = ref('')
    const drug_unit = ref('')
    const drug_direction = ref('')
    const drug_detail = ref('')
    const drug_narcotic2 = ref('0')
    const drug_narcotic3 = ref('0')
    const drug_narcotic4 = ref('0')
    const drug_narcotic5 = ref('0')
    const user_id = ref('')
    const userList = ref([])

    const druggroup_id = ref('')
    const drugtype_id = ref('')
    const drugGroups = ref([])
    const drugTypes = ref([])

    const { drugGroupList } = drugGroup
    const { drugTypeList } = drugType

    const { documentSetting } = document

    const getDocs = () => {
      documentSetting().then(res => {
        drug_id.value = res.drug
      })
    }

    const getDrugGroup = () => {
      drugGroupList({
        searchtext: '',
        drug_category_id: typeAdd.value,
        druggroup_status_id: '1',
      }).then(res => {
        drugGroups.value = res.data
        druggroup_id.value = drugGroups.value[0].druggroup_id
      })
    }

    const getDrugType = () => {
      drugTypeList({
        searchtext: '',
        drug_category_id: typeAdd.value,
        drugtype_status_id: '1',
      }).then(res => {
        drugTypes.value = res.data
        drugtype_id.value = drugTypes.value[0].drugtype_id
      })
    }

    const { userRoleList } = userRole

    const getUser = () => {
      userRoleList(
        {
          role_id: ['1', '3'],
        },
      ).then(res => {
        userList.value = res
        user_id.value = localStorage.getItem('user_id')
      })
    }
    const { drugAdd } = drugStore

    const createDrugStore = () => {
      const isFormValid = formAddDrugStore.value.validate()
      if (!isFormValid) return
      loading.value = true
      let body = {}
      if (typeAdd.value == 1) {
        body = {
          drug_id: drug_id.value,
          drugtype_id: drugtype_id.value,
          druggroup_id: druggroup_id.value,
          drug_name: drug_name.value,
          drug_price: drug_price.value,
          drug_unit: drug_unit.value,
          drug_direction: drug_direction.value,
          drug_detail: drug_detail.value,
          user_id: user_id.value,
          drug_narcotic2: drug_narcotic2.value,
          drug_narcotic3: drug_narcotic3.value,
          drug_narcotic4: drug_narcotic4.value,
          drug_narcotic5: drug_narcotic5.value,
        }
      } else {
        body = {
          drug_id: drug_id.value,
          drugtype_id: drugtype_id.value,
          druggroup_id: druggroup_id.value,
          drug_name: drug_name.value,
          drug_price: drug_price.value,
          drug_unit: drug_unit.value,
          drug_direction: drug_direction.value,
          drug_detail: drug_detail.value,
          user_id: user_id.value,
        }
      }
      const path = typeAdd.value == '1' ? 'drug' : 'drug/equipment'
      drugAdd(path, body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        formAddDrugStore.value.reset()
        loading.value = false
        emit('update:is-add-new-drug-store', false)
        emit('onAdd')
      })
    }

    watch([typeAdd], () => {
      getDrugGroup()
      getDrugType()
      getUser()
      drug_name.value = ''
      drug_direction.value = ''
      drug_price.value = ''
      drug_unit.value = ''
      drug_detail.value = ''
    })

    watch(() => props.isAddNewDrugStore, val => {
      if (val) {
        typeAdd.value = '1'
        drug_name.value = ''
        drug_direction.value = ''
        drug_price.value = ''
        drug_unit.value = ''
        drug_detail.value = ''
        getDocs()
        getDrugGroup()
        getDrugType()
        getUser()
      }
    })

    return {
      createDrugStore,
      formAddDrugStore,
      userList,
      typeAdd,
      druggroup_id,
      drugtype_id,
      drugGroups,
      drugTypes,
      drug_id,
      drug_name,
      drug_price,
      drug_unit,
      drug_direction,
      drug_detail,
      drug_narcotic2,
      drug_narcotic3,
      drug_narcotic4,
      drug_narcotic5,
      user_id,
      loading,
      required,
      textValidation,
    }
  },

}
</script>
