<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Medicine / Equipment') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-2 d-none d-md-block"
          @click="isAddNewDrugStore = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
          <span class="d-none d-sm-block">
            {{ $t("add") }}{{ $t("drug") }}/{{
              $t("equipment")
            }}
          </span>
        </v-btn>
        <v-btn
          color="primary"
          class="me-2 d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddNewDrugStore = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-menu
          offset-y
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              outlined
              class="d-none d-md-block"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="me-1">
                {{ icons.mdiFileImportOutline }}
              </v-icon>
              <span class="d-none d-sm-block">
                {{ $t("import_files") }}
              </span>
              <v-icon
                class="d-none d-sm-block"
                right
              >
                {{ icons.mdiMenuDownOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              fab
              icon
              class="d-block d-md-none"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiFileImportOutline }}
              </v-icon>
            </v-btn>
          </template>
          <v-list
            nav
            dense
          >
            <v-list-item @click="isImportFileDrug = true">
              <v-list-item-title>
                {{ $t("import_drug_files") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="isImportFileEquipment = true">
              <v-list-item-title>
                {{ $t("import_equipment_files") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-select
            v-model.trim="drug_category_id"
            :items="categories"
            :label="$t('category')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-select
            v-model.trim="drug_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
          md="6"
          lg="6"
        >
          <v-autocomplete
            v-model="druggroup_id"
            outlined
            dense
            :items="drugGroups"
            :label="$t('drug_group')"
            item-text="druggroup_name"
            :no-data-text="$t('no_information')"
            item-value="druggroup_id"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="6"
        >
          <v-autocomplete
            v-model="drugtype_id"
            :items="drugTypes"
            outlined
            dense
            :label="$t('type_drug_equipment')"
            :no-data-text="$t('no_information')"
            item-text="drugtype_name"
            item-value="drugtype_id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.drug_id_pri`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.drug_id`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'drugDetail', params: { id: item.drug_id_pri } }"
          >
            {{ item.drug_id }}
          </router-link>
        </template>
        <template v-slot:[`item.drug_name`]="{ item }">
          {{ item.drug_name }}
          <v-icon
            v-if="item.drug_narcotic2 == 1"
            x-small
            style="color: #303d4a"
          >
            {{ icons.mdiCircle }}
          </v-icon>
          <v-icon
            v-if="item.drug_narcotic3 == 1"
            x-small
            style="color: #f88c01"
          >
            {{ icons.mdiCircle }}
          </v-icon>
          <v-icon
            v-if="item.drug_narcotic4 == 1"
            x-small
            style="color: red"
          >
            {{ icons.mdiCircle }}
          </v-icon>
          <v-icon
            v-if="item.drug_narcotic5 == 1"
            x-small
            style="color: #398bf7"
          >
            {{ icons.mdiCircle }}
          </v-icon>
        </template>
        <template v-slot:[`item.drug_category_id`]="{ item }">
          <DrugStatus :type="item.drug_category_id" />
        </template>
        <template v-slot:[`item.drug_price`]="{ item }">
          {{ item.drug_price | formatPrice }}
        </template>
        <template v-slot:[`item.drug_total`]="{ item }">
          <span v-if="item.drug_total != null && item.drug_total != '0.00'">{{
            item.drug_total | formatPrice
          }}</span>
          <v-chip
            v-if="item.drug_total == null || item.drug_total == '0.00'"
            small
            color="error"
            class="v-chip-light-bg error--text"
          >
            <v-icon
              small
            >
              {{ icons.mdiClose }}
            </v-icon>
            {{ $t("empty") }}
          </v-chip>
        </template>
        <template v-slot:[`item.drug_status_id`]="{ item }">
          <StatusBlock :status="item.drug_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'drugDetail', params: { id: item.drug_id_pri } }"
          >
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("detail") }}</span>
            </v-tooltip>
          </router-link>

          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;item.drug_category_id==1?isEditDrug = true:isEditEquipment = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="drug_id_pri = item.drug_id_pri; drug_status_id_update = item.drug_status_id; isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ item.drug_status_id==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.drug_status_id==1? $t("suspend"):$t("normal") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ drug_status_id_update ==1? $t('confirmDrugSuspend'):$t('cancelDrugSuspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processUpdate"
            :disabled="processUpdate"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddNewDrugStore
      v-model="isAddNewDrugStore"
      @onAdd="fetchDataTable('add')"
    />
    <EditDrug
      v-model="isEditDrug"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable('update')"
    />
    <EditEquipment
      v-model="isEditEquipment"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable('update')"
    />
    <DrugImportFile
      v-model="isImportFileDrug"
      @onImport="fetchDataTable('add')"
    />
    <EquipmentImportFile
      v-model="isImportFileEquipment"
      @onImport="fetchDataTable('add')"
    />
  </div>
</template>
<script>

import {
  mdiPlus, mdiCheck, mdiDeleteOutline, mdiInformationOutline, mdiAlertOutline, mdiClose, mdiPencilOutline, mdiFileImportOutline, mdiCircle, mdiMenuDownOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useDrugStore from './useDrugStore'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'
import { formatPrice } from '@/plugins/filters'
import AddNewDrugStore from './AddNewDrugStore.vue'
import EditDrug from './EditDrug.vue'
import EditEquipment from './EditEquipment.vue'
import DrugImportFile from './DrugImprofile.vue'
import EquipmentImportFile from './EquipmentImportFile.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    StatusBlock,
    Pagination,
    DrugStatus,
    AddNewDrugStore,
    EditDrug,
    EditEquipment,
    DrugImportFile,
    EquipmentImportFile,
  },
  filters: {
    formatPrice,
  },

  setup() {
    const isAddNewDrugStore = ref(false)
    const isEditDrug = ref(false)
    const dataEdit = ref({})
    const isEditEquipment = ref(false)
    const isImportFileDrug = ref(false)
    const isImportFileEquipment = ref(false)
    const {
      isUpdateStatus,
      drug_id_pri,
      drug_status_id_update,
      processUpdate,
      updateStatus,
      statusList,
      categories,
      searchtext,
      drug_status_id,
      drug_category_id,
      totalPage,
      druggroup_id,
      drugtype_id,
      drugGroups,
      drugTypes,
      dataTableList,
      totalDataTableList,
      segmentId,
      options,
      columns,
      footer, loading,
      fetchDataTable,
    } = useDrugStore()

    return {

      isImportFileEquipment,
      isImportFileDrug,
      isUpdateStatus,
      drug_id_pri,
      drug_status_id_update,
      processUpdate,
      updateStatus,
      isEditEquipment,
      totalPage,
      isAddNewDrugStore,
      isEditDrug,
      dataEdit,
      statusList,
      categories,
      searchtext,
      drug_status_id,
      drug_category_id,
      druggroup_id,
      drugtype_id,
      drugGroups,
      drugTypes,
      dataTableList,
      totalDataTableList,
      segmentId,
      options,
      columns,
      footer,
      loading,
      fetchDataTable,
      icons: {
        mdiPlus, mdiCheck, mdiDeleteOutline, mdiInformationOutline, mdiAlertOutline, mdiClose, mdiPencilOutline, mdiFileImportOutline, mdiCircle, mdiMenuDownOutline,
      },
    }
  },

}
</script>
<style>

</style>
