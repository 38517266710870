<template>
  <div>
    <v-dialog
      v-model="isImportFileEquipment"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('import_equipment_files') }} <v-spacer></v-spacer>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <a :href="drugtool_form">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  x-small
                  fab
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCloudDownloadOutline }}</v-icon>
                </v-btn></a>
            </template>
            <span>{{ $t("download_form") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-form
          ref="formImportEquipment"
          @submit.prevent="uploadFileEquipment"
        >
          <v-card-text>
            <v-autocomplete
              v-model="druggroup_id"
              :items="drugGroups"
              dense
              outlined
              :rules="[required]"
              :no-data-text="`${$t('no_data')} !`"
              :label="$t('choose_equipment_group')"
              item-text="druggroup_name"
              item-value="druggroup_id"
            ></v-autocomplete>
            <v-autocomplete
              v-model="drugtype_id"
              :rules="[required]"
              dense
              outlined
              :items="drugTypes"
              :no-data-text="`${$t('no_data')} !`"
              :label="$t('equipment_type')"
              item-text="drugtype_name"
              item-value="drugtype_id"
            ></v-autocomplete>
            <v-autocomplete
              v-model.trim="user_id"
              :items="userList"
              :label="$t('doctor')"
              :no-data-text="`${$t('no_data')} !`"
              dense
              outlined
              :rules="[required]"
              item-text="user_fullname"
              item-value="user_id"
              :auto-select-first="true"
            ></v-autocomplete>
            <v-file-input
              v-model="files"
              dense
              outlined
              :label="$t('choose_a_file')"
              show-size
              accept=".csv"
              @change="checkFile"
            ></v-file-input>
            <span class="ml-3">Log</span> <span
              v-if="checkLoading"
              class="primary--text"
            >{{ $t('checkDataLoading') }}</span>
            <v-sheet
              outlined
              rounded="lg"
              height="100"
            >
              <v-virtual-scroll
                :items="dataCheckFile.data"
                :item-height="20"
                height="100"
              >
                <template v-slot:default="{ item,index }">
                  <span
                    class="text-sm ml-2"
                    :class="item.status_id==0?'error--text':'success--text'"
                  > {{ index+1 }}) {{ item.status_id==0? item.status_message :`ข้อมูลถูกต้อง (${item.drug_id} ${item.drug_name})` }} <v-icon
                    small
                    :color="item.status_id==0?'error':'success'"
                  >{{ item.status_id==0? icons.mdiClose:icons.mdiCheck }}</v-icon> </span>
                </template>
              </v-virtual-scroll>
            </v-sheet>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || !files||dataCheckFile.checkfalse>=1 || checkLoading "

              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-import-file-equipment',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCloudDownloadOutline } from '@mdi/js'
import drugGroup from '@/api/DrugManagement/drugGroup'
import drugType from '@/api/DrugManagement/drugType'
import userRole from '@/api/userRole'
import { required } from '@/@core/utils/validation'
import drugtool_form from '@/assets/download_files/drugtool_form.csv'
import drugStore from '@/api/DrugManagement/drugStore'
import store from '@/store'
import checkImportFile from '@/api/checkImportFile'

export default {
  model: {
    prop: 'isImportFileEquipment',
    event: 'update:is-import-file-equipment',
  },
  props: {
    isImportFileEquipment: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const files = ref(null)
    const drugGroups = ref([])
    const drugTypes = ref([])
    const userList = ref([])
    const druggroup_id = ref('')
    const drugtype_id = ref('')
    const user_id = ref('')
    const formImportEquipment = ref(null)
    const loading = ref(false)
    const checkLoading = ref(false)
    const dataCheckFile = ref({})

    const { drugGroupList } = drugGroup
    const { drugTypeList } = drugType
    const { userRoleList } = userRole
    const { equipmentImportFile } = drugStore

    drugGroupList({
      searchtext: '',
      drug_category_id: '2',
      druggroup_status_id: '1',
    }).then(res => {
      drugGroups.value = res.data
      druggroup_id.value = drugGroups.value[0].druggroup_id
    })

    drugTypeList({
      searchtext: '',
      drug_category_id: '2',
      drugtype_status_id: '1',
    }).then(res => {
      drugTypes.value = res.data
      drugtype_id.value = drugTypes.value[0].drugtype_id
    })
    userRoleList({
      role_id: ['1', '3'],
    }).then(res => {
      userList.value = res
      user_id.value = localStorage.getItem('user_id')
    })

    const uploadFileEquipment = () => {
      const isFormValid = formImportEquipment.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('druggroup_id', druggroup_id.value)
      formData.append('drugtype_id', drugtype_id.value)
      formData.append('user_id', user_id.value)
      formData.append('file', files.value)
      equipmentImportFile(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-import-file-equipment', false)
        user_id.value = localStorage.getItem('user_id')
        files.value = null
        drugtype_id.value = drugTypes.value[0].drugtype_id
        druggroup_id.value = drugGroups.value[0].druggroup_id
        emit('onImport')
      })
    }

    const checkFile = () => {
      checkLoading.value = true
      const formData = new FormData()
      formData.append('druggroup_id', druggroup_id.value)
      formData.append('drugtype_id', drugtype_id.value)
      formData.append('user_id', user_id.value)
      formData.append('file', files.value)
      checkImportFile.checkImportFileEquipment(formData).then(res => {
        dataCheckFile.value = res
        checkLoading.value = false
      })
    }

    watch(() => props.isImportFileEquipment, value => {
      if (value) {
        files.value = null
      }
    })

    return {
      files,
      loading,
      drugGroups,
      drugTypes,
      userList,
      user_id,
      druggroup_id,
      drugtype_id,
      formImportEquipment,
      uploadFileEquipment,
      required,
      drugtool_form,
      checkFile,
      checkLoading,
      dataCheckFile,
      icons: {
        mdiCloudDownloadOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
