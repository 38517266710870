import basicStatus from '@/@fake-db/data/basicStatus.json'
import category_drug_type_for_search from '@/@fake-db/data/category_drug_type_for_search.json'
import drugGroup from '@/api/DrugManagement/drugGroup'
import drugStore from '@/api/DrugManagement/drugStore'
import drugType from '@/api/DrugManagement/drugType'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useDrugStore() {
  // search valiable
  const searchtext = ref('')
  const drug_status_id = ref(1)
  const drug_category_id = ref('')
  const statusList = ref(basicStatus.data)
  const categories = ref(category_drug_type_for_search.data)
  const druggroup_id = ref('')
  const drugtype_id = ref('')
  const drugGroups = ref([])
  const drugTypes = ref([])

  // data tables
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'drug_id_pri',
      width: 30,
    },
    {
      text: i18n.t('code'),
      value: 'drug_id',
      width: 100,
    },
    {
      text: i18n.t('name'),
      value: 'drug_name',

      width: 150,
    },
    {
      text: i18n.t('group'),
      value: 'drug_category_id',
      align: 'center',

      width: 100,
    },
    {
      text: i18n.t('category'),
      value: 'druggroup_name',

      width: 150,
    },
    {
      text: i18n.t('type'),
      value: 'drugtype_name',

      width: 150,
    },
    {
      text: i18n.t('sale_price'),
      value: 'drug_price',
      width: 150,
    },
    {
      text: i18n.t('balance'),
      value: 'drug_total',
      width: 150,
    },
    {
      text: i18n.t('unit'),
      value: 'drug_unit',

      width: 100,
    },
    {
      text: i18n.t('status'),
      value: 'drug_status_id',
      align: 'center',

      width: 100,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      width: 180,
      align: 'center',
    },
  ])

  // is suspend drug container
  const isUpdateStatus = ref(false)
  const drug_id_pri = ref('')
  const drug_status_id_update = ref('')
  const processUpdate = ref(false)

  const { drugList, drugUpdateStatus } = drugStore
  const { drugGroupList } = drugGroup
  const { drugTypeList } = drugType

  drugGroupList({
    searchtext: '',
    druggroup_status_id: '1',
  }).then(res => {
    drugGroups.value = res.data
    drugGroups.value.unshift({
      druggroup_id: '',
      druggroup_name: i18n.t('all_categories'),
    })
  })

  drugTypeList({
    searchtext: '',
    drugtype_status_id: '1',
  }).then(res => {
    drugTypes.value = res.data
    drugTypes.value.unshift({
      drugtype_id: '',
      drugtype_name: i18n.t('all_types'),
    })
  })

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    drugList({
      searchtext: searchtext.value,
      drug_status_id: drug_status_id.value,
      drug_category_id: drug_category_id.value,
      druggroup_id: druggroup_id.value,
      drugtype_id: drugtype_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    })
      .then(res => {
        const { data, count, segment, count_of_page } = res
        dataTableList.value = data
        totalDataTableList.value = count
        segmentId.value = segment
        totalPage.value = count_of_page
        loading.value = false
      })
      .catch(err => {
        store.commit('app/ALERT', {
          message: 'ERROR',
          color: 'error',
        })
        loading.value = false
        console.log('err : ', err)
      })
  }

  const updateStatus = () => {
    processUpdate.value = true
    drugUpdateStatus({
      drug_id_pri: drug_id_pri.value,
      drug_status_id: drug_status_id_update.value == '1' ? '2' : '1',
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      processUpdate.value = false
      fetchDataTable()
      isUpdateStatus.value = false
    })
  }

  watch([searchtext, drug_category_id, drug_status_id, druggroup_id, drugtype_id, options], (newvalue, oldvalue) => {
    if (
      newvalue[0] !== oldvalue[0] ||
      newvalue[1] !== oldvalue[1] ||
      newvalue[2] !== oldvalue[2] ||
      newvalue[3] !== oldvalue[3] ||
      newvalue[4] !== oldvalue[4]
    ) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    isUpdateStatus,
    drug_id_pri,
    drug_status_id_update,
    processUpdate,
    updateStatus,

    statusList,
    categories,
    searchtext,
    drug_status_id,
    drug_category_id,
    druggroup_id,
    drugtype_id,
    drugGroups,
    drugTypes,
    totalPage,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    columns,
    footer,
    loading,
    fetchDataTable,
  }
}
